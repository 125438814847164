import React from 'react';
import styled from 'styled-components';



const Box = styled.div`
overflow-x: hidden;
padding: 70px 10px;
background: #DAE2F8;
font-family: 'Quicksand';
background: linear-gradient(to right, #D6A4A4, #DAE2F8); 
background: -webkit-linear-gradient(to right, #D6A4A4, #DAE2F8);
`

const H1 = styled.h1`
text-align: center;
font-family: 'Megrim';
`

const P = styled.p`
text-align: center;
padding: 40px 30px 30px 30px;
`

const Pcon = styled.p`
text-align: center;
padding: 0px 30px 30px 30px;
`

const ConForm = styled.div`
font-family: 'Quicksand';
padding: 0px 20px;
`

const ConButton = styled.div`
display: flex; 
justify-content: center;
padding-top: 30px;
`



function Reviews() {
    return (
        <Box>
            <H1>CUSTOMER REVIEWS</H1>
            <P>Submit a review below and let others about your experience. Thank you for your time.</P>

            {/* CONTACT FORM */}
            <div class="col-lg-12 col-md-6">
                <ConForm>
                    <Pcon>Please fill out the form below, we'll get back to you within 24hrs!</Pcon>
                    <form>
                        <div class="form-group row">
                            <label for="inputEmail3" class="col-sm-2 col-form-label">Name</label>
                            <div class="col-sm-10">
                                <input type="email" class="form-control" id="inputEmail3" placeholder="First Initial, Last Name" />
                            </div>
                        </div>
                        <div class="form-group row pt-2">
                            <label for="exampleFormControlTextarea1" class="col-sm-2 col-form-label">Review</label>
                            <div class="col-sm-10">
                                <textarea type="password" class="form-control" id="exampleFormControlTextarea1" placeholder="How was your experience working with us?" />
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <ConButton><a href="mailto:telephonemoney@icloud.com" type="submit" class="btn btn-primary">Submit Review</a></ConButton>
                        </div>
                    </form>
                </ConForm>
            </div>
        </Box>
    )
}

export default Reviews;