import React from 'react';
import styled from 'styled-components';



const Box = styled.div`
overflow-x: hidden;
padding: 0px 10px;
background: #DAE2F8;
font-family: 'Quicksand';
background: linear-gradient(to right, #D6A4A4, #DAE2F8); 
background: -webkit-linear-gradient(to right, #D6A4A4, #DAE2F8);
`



function Pictures() {
    return (
        <Box>
            <div class="row">
                <div class="col-lg-4 col-md-4 ">
                    <img src="https://i.imgur.com/HUAckqY.jpg" class="img-fluid mb-4" alt="" />
                    <img src="https://i.imgur.com/4jCr356.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.3s" />
                </div>
                <div class="col-lg-4 col-md-4">
                    <img src="https://i.imgur.com/Ss3teVM.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.1s" />
                    <img src="https://i.imgur.com/WjPdWUY.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.4s" />
                </div>
                <div class="col-lg-4 col-md-4">
                    <img src="https://i.imgur.com/DUyCfXZ.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.2s" />
                    <img src="https://i.imgur.com/ICB8uYh.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.5s" />
                </div>
               
            </div>

            
            <img src="https://i.imgur.com/NUQMvhP.jpg" class="img-fluid mb-4" alt=""
                data-wow-delay="0.3s" />


            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <img src="https://i.imgur.com/VS1fyns.jpg" class="img-fluid mb-4" alt="" />
                    <img src="https://i.imgur.com/W60tv8G.png" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.3s" />
                </div>
                <div class="col-lg-4 col-md-4">
                    <img src="https://i.imgur.com/Vblmm2y.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.1s" />
                    <img src="https://i.imgur.com/7vb5MSk.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.4s" />
                </div>
                <div class="col-lg-4 col-md-4">
                    <img src="https://i.imgur.com/NUmzwU1.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.2s" />
                    <img src="https://i.imgur.com/sCppfkJ.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.5s" />
                </div>
            </div>

            <img src="https://i.imgur.com/TOS7q09.jpg" class="img-fluid mb-4" alt=""
                data-wow-delay="0.3s" />

            
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <img src="https://i.imgur.com/leCxBRb.jpg" class="img-fluid mb-4" alt="" />
                    <img src="https://i.imgur.com/E8DNTyU.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.3s" />
                </div>
                <div class="col-lg-4 col-md-4">
                    <img src="https://i.imgur.com/UWTOoZe.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.1s" />
                    <img src="https://i.imgur.com/vq5WajA.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.4s" />
                </div>
                <div class="col-lg-4 col-md-4">
                    <img src="https://i.imgur.com/50y2qVP.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.2s" />
                    <img src="https://i.imgur.com/KjW9ub8.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.5s" />
                </div>
            </div>

            <img src="https://i.imgur.com/W912clh.jpg" class="img-fluid mb-4" alt="" />
            
        </Box>
    )
}

export default Pictures;