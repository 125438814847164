import React from 'react';
import './App.css';
import Navi from './components/Navi';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Pricing from './components/pages/Pricing';
import Contact from './components/pages/Contact';
import Pictures from './components/pages/Pictures';
import Footer from './components/Footer';
import Videos from './components/pages/Videos';
import Graphics from './components/pages/Graphics';
import Audio from './components/pages/Audio';
import Reviews from './components/pages/Reviews';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';



function App() {
  return (
    <Router>
      <div>
        <Navi />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/Services" component={Services} />
          <Route path="/Pricing" component={Pricing} />
          <Route path="/Contact" component={Contact} />
          <Route path="/Pictures" component={Pictures} />
          <Route path="/Videos" component={Videos} />
          <Route path="/Graphics" component={Graphics} />
          <Route path="/Audio" component={Audio} />
          <Route path="/Reviews" component={Reviews} />
        </Switch>
        <Footer />
      </div>
    </Router>

  );
}

export default App;


