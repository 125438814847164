import React from 'react';
import styled from 'styled-components';



const Box = styled.div`
overflow-x: hidden;
padding: 0px 10px;
background: #DAE2F8;
font-family: 'Quicksand';
background: linear-gradient(to right, #D6A4A4, #DAE2F8); 
background: -webkit-linear-gradient(to right, #D6A4A4, #DAE2F8);
`



function Graphics() {
    return (
        <Box>
            <div class="row">
                <div class="col-lg-4 col-md-4 ">
                    <img src="https://i.imgur.com/u9HwMvi.jpg" class="img-fluid mb-4" alt="" />
                </div>
                <div class="col-lg-4 col-md-4">
                    <img src="https://i.imgur.com/dzrsEo5.png" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.1s" />
                </div>
                <div class="col-lg-4 col-md-4">
                    <img src="https://i.imgur.com/OjRtnfV.png" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.1s" />
                </div>
            </div>

            <img src="https://i.imgur.com/DgKgS3G.png" class="img-fluid mb-4" alt=""
                data-wow-delay="0.1s" />

            <img src="https://i.imgur.com/uvBcV3Z.png" class="img-fluid mb-4" alt=""
                data-wow-delay="0.1s" />
        </Box>
    )
}

export default Graphics;