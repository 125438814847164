import React from 'react';
import { Nav, Navbar, Form, FormControl, Button } from "react-bootstrap";
import styled from 'styled-components';



const Box = styled.div`
top: 0;
z-index: 100;
position: sticky;
background: #DAE2F8;  
font-family: 'Quicksand';
background: linear-gradient(to right, #D6A4A4, #DAE2F8);
background: -webkit-linear-gradient(to right, #D6A4A4, #DAE2F8);  
`



function Navi() {
  return (
    <Box id="homie">
      <Navbar expand="md">
        <Navbar.Brand href="/">TELEPHONE MONEY</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="./Services">Services</Nav.Link>
            <Nav.Link href="./Pricing">Pricing</Nav.Link>
            <Nav.Link href="./Contact">Contact</Nav.Link>
          </Nav>
          <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2 btn-sm" />
            <Button variant="outline-secondary btn-sm">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </Box>
  );
}

export default Navi;
