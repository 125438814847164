import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
/* padding: 0px 10px; */
background: #DAE2F8; 
font-family: 'Quicksand';
background: linear-gradient(to right, #D6A4A4, #DAE2F8); 
background: -webkit-linear-gradient(to right, #D6A4A4, #DAE2F8);
`

const Span = styled.div`
text-align: center;
`

function Footer() {
    return (
        <Box>
            < footer class="page-footer font-small unique-color-dark" style={{ backgroundColor: "#bdbdbd" }}>
                <div style={{ backgroundColor: "#ff80ab" }}>
                    <div class="container">
                        <div class="row py-4 d-flex align-items-center">

                            <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
                                <h6 class="mb-0">Connect with us on social media!</h6>
                            </div>

                            <div class="col-md-6 col-lg-7 text-center text-md-right">
                                <a href="https://www.instagram.com/telephonemoneystudios" target="blank" class="ins-ic">
                                    <i class="fab fa-instagram white-text mr-4" style={{ color: "white" }}> </i>
                                </a>
                                <a href="https://www.vimeo.com/telephonemoney" target="blank" class="vi-ic">
                                    <i class="fab fa-vimeo-v white-text mr-4" style={{ color: "white" }}> </i>
                                </a>
                                <a href="https://www.soundcloud.com/telephonemoney" target="blank" class="sc-ic">
                                    <i class="fab fa-soundcloud white-text mr-4" style={{ color: "white" }}> </i>
                                </a>
                                <a href="https://www.github.com/telephonemoney" target="blank" class="gh-ic">
                                    <i class="fab fa-github white-text " style={{ color: "white" }}> </i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container text-center text-md-left mt-5">
                    <div class="row mt-3">

                        <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                            <h6 class="text-uppercase font-weight-bold">Telephone Money</h6>
                            <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
                            <p>Thank you for choosing our team for your production needs. Feel free to contact us with any questions or concerns. </p>
                        </div>
                        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 class="text-uppercase font-weight-bold">Resources</h6>
                            <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
                            <p>
                                <a href="#!">Our Team</a>
                            </p>
                            <p>
                                <a href="#!">Our Equipment</a>
                            </p>
                            <p>
                                <a href="#!">Become A Partner</a>
                            </p>
                        </div>

                        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 class="text-uppercase font-weight-bold">Useful links</h6>
                            <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
                            <p>
                                <a href="./Reviews">Reviews</a>
                            </p>
                            <p>
                                <a href="./Contact">Report A Bug</a>
                            </p>
                            <p>
                                <a href="./Contact">Leave A Message</a>
                            </p>
                        </div>

                        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            <h6 class="text-uppercase font-weight-bold">Contact Info</h6>
                            <hr class="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{ width: "60px" }} />
                            <p>
                                <i class="fas fa-map-marker-alt mr-3"></i> Atlanta Ga. 30315</p>
                            <p>
                                <i class="fas fa-phone mr-3"></i> 678-783-6905</p>
                            <p>
                                <i class="fas fa-envelope mr-2"></i>TelephoneMoney@icloud.com</p>
                        </div>
                    </div>
                </div>

                <Span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=r52KTNTL64wBIpBOC9gqI3vdBz5JDLpiaecJX6JefXtvJ2QNbvTH3KkOd1V5"></script></Span>
                <div
                    class="footer-copyright text-center py-3">© 2020 Copyright: Telephone Money
                </div>
            </footer >
        </Box>
    );
}

export default Footer;