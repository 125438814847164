import React from 'react';
import styled from 'styled-components';
import VideoPlayer from '../VideoPlayer';
import Carousel from 'react-bootstrap/Carousel';



const Box = styled.div`
overflow-x: hidden;
padding: 0px 10px;
background: #DAE2F8;
font-family: 'Quicksand';
background: linear-gradient(to right, #D6A4A4, #DAE2F8); 
background: -webkit-linear-gradient(to right, #D6A4A4, #DAE2F8);
`

const Vid = styled.div`
padding-top: 110px;
`

const H1 = styled.h1`
text-align: center;
padding: 70px 0px 10px 0px;
font-family: 'Megrim';
`

const H1vid = styled.h1`
text-align: center;
padding: 130px 0px 30px 0px;
font-family: 'Megrim';
`

const H1aud = styled.h1`
text-align: center;
padding: 130px 0px 30px 0px;
font-family: 'Megrim';
`

const H1web = styled.h1`
text-align: center;
padding: 120px 0px 30px 0px;
font-family: 'Megrim';
`

const P = styled.p`
text-align: center;
padding-bottom: 15px;
`

const Pvid = styled.p`
text-align: center;
padding: 0px 100px 20px 100px;
`

const Aud = styled.div`
text-align: center;
padding-top: 90px;
`

const Paud = styled.p`
text-align: center;
padding: 0px 40px 20px 40px;
`

const Phobutton = styled.div`
display: flex;
justify-content: center;
`

const Vidbutton = styled.div`
display: flex;
justify-content: center;
`

const Pweb = styled.p`
text-align: center;
padding: 0px 70px 20px 70px;
`

const Grabutton = styled.div`
display: flex;
justify-content: center;
`

const Audbutton = styled.div`
display: flex;
justify-content: center;
`

const Webbutton = styled.div`
display: flex;
justify-content: center;
padding-bottom: 20px;
`

const Webimg = styled.div`
padding: 90px 0px 20px 0px;
`



function Showcase() {
    return (
        <Box>
            {/* <Banner><img src="https://i.imgur.com/ANTVAmt.png" class="img-fluid mb-4" alt="" /></Banner> */}
            {/* PHOTOGRAPHY SECTION */}
            <H1>PHOTOGRAPHY</H1>
            <P>Whether you are looking for an event photographer, or would just like to schedule a fun photoshoot. We enjoy shooting! </P>
            <div class="row">
                <div class="col-lg-4 col-md-12 mb-4">
                    <img src="https://i.imgur.com/vQep6fe.jpg" class="img-fluid mb-4" alt="" />
                    <img src="https://i.imgur.com/Vblmm2y.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.3s" />
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <img src="https://i.imgur.com/osIqkwP.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.1s" />
                    <img src="https://i.imgur.com/uYAakD8.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.4s" />
                </div>
                <div class="col-lg-4 col-md-6 mb-4">
                    <img src="https://i.imgur.com/leCxBRb.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.2s" />
                    <img src="https://i.imgur.com/F4yYdJY.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.5s" />

                </div>
            </div>
            <Phobutton><a href="./Pictures" type="button" class="btn btn-primary mr-3">View Pictures</a>
                <a href="./Pricing" type="button" class="btn btn-secondary ml-3">Pricesheet</a>
            </Phobutton>


            {/* VIDEOGRAPHY SECTION */}
            <div class="row">
                <div class="col-lg-6">
                    <Vid> <VideoPlayer /></Vid>
                </div>
                <div class="col-lg-6">
                    <H1vid>VIDEOGRAPHY</H1vid>
                    <Pvid>Let us help capture the moment on your special day. We also enjoy shooting music videos of all genres. Take a look! </Pvid>
                    <Pvid>Videos Hosted by Vimeo.com</Pvid>
                    <Vidbutton><a href="./Videos" type="button" class="btn btn-primary mr-3">View Videos</a>
                        <a href="./Pricing" type="button" class="btn btn-secondary ml-3">Pricesheet</a>
                    </Vidbutton>
                </div>
            </div>


            {/* GRAPHIC DESIGN SECTION */}
            <H1>GRAPHIC DESIGN</H1>
            <P>Whether you need an album cover or would like for us to help create a logo for your brand. We can help! </P>
            <img src="https://i.imgur.com/aVn2c9V.png" class="img-fluid mb-4" alt=""
                data-wow-delay="0.5s" />
            <Grabutton><a href="./Graphics" type="button" class="btn btn-primary mr-3">View Designs</a>
                <a href="./Pricing" type="button" class="btn btn-secondary ml-3">Pricesheet</a>
            </Grabutton>


            {/* AUDIO ENGINEERING SECTION */}
            <div class="row">
                <div class="col-lg-6">
                    <H1aud>AUDIO ENGINEERING</H1aud>
                    <Paud>Take a listen to some of the amazing songs recorded & mixed at our studio located in Atlanta. Let us mix your next track! </Paud>
                    <Paud>Audio Hosted by SoundCloud.com</Paud>
                    <Audbutton><a href="./Audio" type="button" class="btn btn-primary mr-3">Listen to Mixes</a>
                        <a href="./Pricing" type="button" class="btn btn-secondary ml-3">Pricesheet</a>
                    </Audbutton>
                </div>
                <div class="col-lg-6">
                    <Aud><iframe title="Music" width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/574858791&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                        <iframe title="Music" width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/723315721&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe></Aud>
                </div>
            </div>


            {/* WEB DEVELOPMENT SECTION */}
            <div class="row">
                <div class="col-lg-6">
                    <Webimg>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://i.imgur.com/9qXEt0P.png"
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://i.imgur.com/qTINNlf.png"
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://i.imgur.com/I36usOg.png"
                                    alt="Fourth slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Webimg>
                </div>
                <div class="col-lg-6">
                    <H1web>WEB DEVELOPMENT</H1web>
                    <Pweb>Visit cool websites built by our team. We use HTML, CSS, Javascript, & React! Built for mobile & desktop optimization. </Pweb>
                    <Pvid>Websites Hosted by GoDaddy.com</Pvid>
                    <Webbutton><a href="#1" type="button" class="btn btn-primary mr-3">View Websites</a>
                        <a href="./Pricing" type="button" class="btn btn-secondary ml-3">Pricesheet</a>
                    </Webbutton>
                </div>
            </div>
        </Box >
    );
}

export default Showcase;