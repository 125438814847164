import React from 'react';
import styled from 'styled-components';



const Box = styled.div`
overflow-x: hidden;
padding: 70px 10px;
background: #DAE2F8;
font-family: 'Quicksand';
background: linear-gradient(to right, #D6A4A4, #DAE2F8);
background: -webkit-linear-gradient(to right, #D6A4A4, #DAE2F8);
`

const H1pay = styled.h1`
text-align: center;
font-family: 'Megrim';
`

const H1 = styled.h1`
text-align: center;
padding: 70px 0px 0px 0px;
font-family: 'Megrim';
`

const Ppri = styled.p`
text-align: center;
`

const Pp = styled.p`
text-align: center;
padding: 0px 20px 40px 20px;
`

const Ppay = styled.p`
text-align: center;
padding: 40px 0px 0px 0px;
`

const Pay = styled.div`
text-align: left;
`

const Payday = styled.div`
display:flex;
justify-content: center;
`

const Payments = styled.div`
text-align: center;
`



function Pricing() {
  return (
    <Box>
      <Payments>
        <div class="row">

          <div class="col-lg-6 col-md-6">
            <img src="https://i.imgur.com/5b8WpDs.png" class="img-fluid mb-4" alt="" />
          </div>

          <div class="col-lg-6 col-md-6">
            <H1pay>PAYMENT METHODS</H1pay>
            <Pp>We accept the following: </Pp>
            <Payday>
              <Pay>
                <h2><p class="fab fa-apple"></p>  Apple Pay</h2>
                <h2><p class="fab fa-paypal"></p>  Paypal</h2>
                <h2><p class="fas fa-dollar-sign"></p>  Cash App</h2>
              </Pay>
            </Payday>
            <Ppay>* 50% deposit is required to book services </Ppay>
          </div>
        </div>
      </Payments>


      <H1>PHOTOGRAPHY</H1>
      <section class="pricing py-4">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Portrait</h5>
                  <h6 class="card-price text-center">$200</h6>
                  <Ppri>Ex: Headshots | Close-ups</Ppri>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>One Hour Session</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>20 Digital Pictures</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Professional Edits</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>2 Day Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/portrait" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Photoshoot</h5>
                  <h6 class="card-price text-center">$350</h6>
                  <Ppri>Ex: Candid | Engagement</Ppri>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Two Hour Session</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>30 Digital Pictures</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Professional Edits</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>2 Day Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/photoshoot" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>
            

            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Special Event</h5>
                  <h6 class="card-price text-center">$600</h6>
                  <Ppri>Ex: Wedding | Quincenera</Ppri>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Duration of Event</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>100+ Digital Pictures</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Professional Edits</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>1 Week Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/pic-special-event" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <H1>VIDEOGRAPHY</H1>
      <section class="pricing py-4">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Music Video</h5>
                  <h6 class="card-price text-center">$400</h6>
                  <Ppri>Ex: Artist Promo</Ppri>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Two Locations</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Up to 4 Scenes</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>1 Revision Edit</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>1 Week Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/standard-music-video" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Commercial</h5>
                  <h6 class="card-price text-center">$500</h6>
                  <Ppri>Ex: Business Promoting</Ppri>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Two Locations</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Up to 6 Scenes</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>2 Revision Edits</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>1 Week Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/advanced-music-video" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Special Event</h5>
                  <h6 class="card-price text-center">$1000</h6>
                  <Ppri>Ex: Wedding | Quincenera</Ppri>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Duration of Event</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>10-15 Minute Video</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Professional Edit</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>1 Week Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/vid-special-event" target="blank" class="btn btn-block btn-primary">Schedule Service</a>                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <H1>GRAPHIC DESIGN</H1>
      <section class="pricing py-4">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Flyer Design</h5>
                  <h6 class="card-price text-center">$75</h6>
                  <Ppri>Ex: Event | Sales</Ppri>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>High Quality Flyer</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Custom Color Design</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>1 Revision</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>2 Day Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/flyer-design" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Cover Design</h5>
                  <h6 class="card-price text-center">$100</h6>
                  <Ppri>Ex: Album | Book</Ppri>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>High Quality Cover</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Custom Color Design</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>2 Revisions</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>3 Day Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/cover-design" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Logo Design</h5>
                  <h6 class="card-price text-center">$150</h6>
                  <Ppri>Ex: Branding | Business</Ppri>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>High Quality Logo</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Custom Color Design</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>2 Revisions</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>1 Week Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/logo-design" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <H1>AUDIO ENGINEERING</H1>
      <section class="pricing py-4">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Mix Session</h5>
                  <h6 class="card-price text-center">$30</h6>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>30 Minute Session</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Mixing Only</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>MP3 & WAV Formats</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>1 Day Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/mix-session" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Hourly Session</h5>
                  <h6 class="card-price text-center">$50</h6>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>1 Hour Session</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Recording & Mixing</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>MP3 & WAV Formats</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>1 Day Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/hourly-session" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Block Session</h5>
                  <h6 class="card-price text-center">$150</h6>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>4 Hour Session</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Recording & Mixing</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>MP3 & WAV Formats</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>1 Day Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/block-session" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <H1>WEB DEVELOPMENT</H1>
      <section class="pricing py-4">
        <div class="container">
          <div class="row">

            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Static Website</h5>
                  <h6 class="card-price text-center">$800</h6>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>HTML, CSS, & JS</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>2GB Storage</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>One Page</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>1 Week Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/static-website" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">E-Comm Website</h5>
                  <h6 class="card-price text-center">$1500</h6>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>HTML, CSS, JS, & React</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>5GB Storage</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Up to 3 Pages</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>2 Week Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/e-commerce-website" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-muted text-uppercase text-center">Dynamic Website</h5>
                  <h6 class="card-price text-center">$2100</h6>
                  <hr />
                  <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>HTML, CSS, JS, & React</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>10GB Storage</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>Up to 5 Pages</li>
                    <li><span class="fa-li"><i class="fas fa-minus"></i></span>2 Week Turnaround</li>
                  </ul>
                  <a href="https://calendly.com/telephonemoney/dynamic-website" target="blank" class="btn btn-block btn-primary">Schedule Service</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Box>
  );
}

export default Pricing;