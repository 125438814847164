import React from 'react';
import styled from 'styled-components';



const Box = styled.div`
overflow-x: hidden;
padding: 70px 10px 40px 10px;
background: #DAE2F8;
background: linear-gradient(to right, #D6A4A4, #DAE2F8);
background: -webkit-linear-gradient(to right, #D6A4A4, #DAE2F8);
`

const ConForm = styled.div`
font-family: 'Quicksand';
padding: 0px 20px;
`

const Flex = styled.div`
display: flex;
flex-direction: column;
`

const H1 = styled.h1`
text-align: center;
font-family: 'Megrim';
padding: 0px 0px 10px 0px;
`

const Pcon = styled.p`
text-align: center;
padding: 60px 0px 20px 0px;
font-family: 'Quicksand';
`

const ConButton = styled.div`
display: flex; 
justify-content: center;
padding-top: 30px;
`

const Cal = styled.div`
padding-top: 30px;
min-width: 320px;
`



function Form() {
    return (
        <Box>
            <H1> CONTACT US TODAY</H1>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <Cal><div class="calendly-inline-widget" style={{ height: "480px" }} data-url="https://calendly.com/telephonemoney"></div></Cal>
                </div>

                {/* CONTACT FORM */}
                <div class="col-lg-6 col-md-6">
                    <ConForm>
                        <Pcon>Please fill out the form below, we'll get back to you within 24hrs!</Pcon>
                        <form>
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
                                <div class="col-sm-10">
                                    <input type="email" class="form-control" id="inputEmail3" placeholder="Email" />
                                </div>
                            </div>
                            <div class="form-group row pt-2">
                                <label for="exampleFormControlTextarea1" class="col-sm-2 col-form-label">Description</label>
                                <div class="col-sm-10">
                                    <textarea type="password" class="form-control" id="exampleFormControlTextarea1" placeholder="Ex: How much is a family photoshoot?" />
                                </div>
                            </div>
                            <fieldset class="form-group pt-2">
                                <div class="row">
                                    <legend class="col-form-label col-sm-2 pt-0">Service</legend>
                                    <div class="col-sm-10">
                                        <Flex> <div class="form-check pr-3">
                                            <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option1" />
                                            <label class="form-check-label" for="gridRadios1">
                                                Photography
          </label>
                                        </div>
                                            <div class="form-check pr-3 pt-1">
                                                <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option2" />
                                                <label class="form-check-label" for="gridRadios2">
                                                    Videography
          </label>
                                            </div>
                                            <div class="form-check pr-3 pt-1">
                                                <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option3" />
                                                <label class="form-check-label" for="gridRadios3">
                                                    Graphic Design
          </label>
                                            </div>
                                            <div class="form-check pr-3 pt-1">
                                                <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option4" />
                                                <label class="form-check-label" for="gridRadios4">
                                                    Audio Engineering
          </label>
                                            </div>
                                            <div class="form-check pr-3 pt-1">
                                                <input class="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="option5" />
                                                <label class="form-check-label" for="gridRadios5">
                                                    Web Development
          </label>
                                            </div>
                                        </Flex>

                                    </div>
                                </div>
                            </fieldset>
                            <div class="col-sm-12">
                                <ConButton><a href="mailto:telephonemoney@icloud.com" type="submit" class="btn btn-primary">Submit Email</a></ConButton>
                            </div>
                        </form>
                    </ConForm>


                </div>
            </div>
        </Box>
    );
}

export default Form;

