import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-bootstrap/carousel';



const Box = styled.div`
overflow-x: hidden;
padding: 70px 10px 40px 10px;
background: #DAE2F8;  
font-family: 'Quicksand';
background: linear-gradient(to right, #D6A4A4, #DAE2F8);
background: -webkit-linear-gradient(to right, #D6A4A4, #DAE2F8);
`

const H1 = styled.h1`
text-align: center;
font-family: 'Megrim';
padding-top: 20px;
`

const H1fea = styled.h1`
text-align: center;
font-family: 'Megrim';
padding-top: 40px;
`

const P = styled.p`
text-align: center;
padding: 40px 30px 30px 30px;
`

const Pfea = styled.p`
text-align: center;
padding: 0px 10px;
`

const Clikr = styled.div`
display: flex;
justify-content: center;
`

const Featured = styled.div`
padding-top: 20px;
`

const Carouselo = styled.div`
padding: 0px 0px 10px 0px;
`



function Home() {
    return (
        <Box>
            <div class="row">
                <div class="col-lg-7 col-md-12">
                    <img src="https://i.imgur.com/dfLnQAe.png" class="img-fluid mb-4" alt="" />

                </div>
                <div class="col-lg-5 col-md-12">
                    <H1>INTRO</H1>
                    <P>Hello, we are a one-stop company for your high quality production needs. Specializing in Photography,
                    Videography, Graphic Design, Audio Engineering, & Web Development using up-to-date software such
                        as Adobe Creative Cloud, Protools, and Visual Studio to name a few. Contact us today with any questions or for a quote!</P>
                    <Clikr><a href="./Contact" type="button" class="btn btn-primary ml-3">Request Quote</a>
                        <a href="./Services" type="button" class="btn btn-secondary ml-3">View Services</a>
                    </Clikr>
                </div>
            </div>


            <H1fea>FEATURED</H1fea>
            <Pfea>Take a look at some of our favorite works of art! </Pfea>
            <Featured> <div class="row">

                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                    <img src="https://i.imgur.com/vQep6fe.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.2s" />
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                    <img src="https://i.imgur.com/B6KoIML.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.1s" />
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                    <img src="https://i.imgur.com/A6vTedD.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.4s" />
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                    <img src="https://i.imgur.com/leCxBRb.jpg" class="img-fluid mb-4" alt=""
                        data-wow-delay="0.3s" />
                </div>

            </div>
            </Featured>


            <H1fea>TM PACKAGES</H1fea>
            <Pfea>Are you an Entertainer? Having a Party? Getting Married? These bundles may help you save $ </Pfea>
            <div class="row">
                <div class="col-lg-6 col-md-6 mb-4">
                    <Carouselo>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://i.imgur.com/W60tv8G.png"
                                    alt="First slide"
                                />
                                <Carousel.Caption style={{ background: "rgba(0,0,0,.7)" }}>
                                    <h5>Artist Package</h5>
                                    <h6>$700</h6>
                                    <p>Photoshoot, Standard Music Video, & Block Session! </p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://i.imgur.com/NUQMvhP.jpg"
                                    alt="Third slide"
                                />
                                <Carousel.Caption style={{ background: "rgba(0,0,0,.7)" }}>
                                    <h5>Model Package</h5>
                                    <h6>$900</h6>
                                    <p>Portrait & Static Website!</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://i.imgur.com/4jCr356.jpg"
                                    alt="Third slide"
                                />
                                <Carousel.Caption style={{ background: "rgba(0,0,0,.7)" }}>
                                    <h5>Actor Package</h5>
                                    <h6>$850</h6>
                                    <p>Photoshoot, 2 Min Promo Video, & Static Website! </p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Carouselo>
                </div>


                <div class="col-lg-6 col-md-6 mb-6">
                    <Carouselo>
                        <Carousel>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://i.imgur.com/q52eb8t.jpg"
                                    alt="First slide"
                                />
                                <Carousel.Caption style={{ background: "rgba(0,0,0,.7)" }}>
                                    <h5>Entreprenuer Package</h5>
                                    <h6>$1100</h6>
                                    <p>Photoshoot, 2 Min Commercial, & Static Website! </p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://i.imgur.com/ltwHqXd.jpg"
                                    alt="Third slide"
                                />

                                <Carousel.Caption style={{ background: "rgba(0,0,0,.7)" }}>
                                    <h5>Birthday Package</h5>
                                    <h6>$500</h6>
                                    <p>Photoshoot & 3 Min Video! </p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="https://i.imgur.com/Vblmm2y.jpg"
                                    alt="Third slide"
                                />

                                <Carousel.Caption style={{ background: "rgba(0,0,0,.7)" }}>
                                    <h5>Wedding Package</h5>
                                    <h6>$1200</h6>
                                    <p>Special Event Photography & Videography!</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Carouselo>
                </div>
            </div>
        </Box>
    );
}

export default Home;
