import React from 'react';
import styled from 'styled-components';



const Box = styled.div`
overflow-x: hidden;
padding: 20px 10px;
background: #DAE2F8;
font-family: 'Quicksand';
background: linear-gradient(to right, #D6A4A4, #DAE2F8); 
background: -webkit-linear-gradient(to right, #D6A4A4, #DAE2F8);
`



function Audio() {
    return (
        <Box>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <iframe width="100%" height="450" title="soundcloud" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/477279213&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                </div>
                <div class="col-lg-6 col-md-6">
                    <iframe width="100%" height="450" title="soundcloud" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/281934460&color=%23232a23&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                </div>
                </div>
        </Box>
    )
}

export default Audio;