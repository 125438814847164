import React from 'react';
import ReactPlayer from 'react-player';



const VideoPlayer = () => {
    return (
        <div className='player-wrapper'>
            <ReactPlayer
                className='react-player'
                url='https://vimeo.com/390233891'
                width='100%'
                height='100%'
            />
        </div>
    )
}

export default VideoPlayer;