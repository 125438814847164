import React from 'react';
import styled from 'styled-components';
import VideoPlayer from '../VideoPlayer';



const Box = styled.div`
overflow-x: hidden;
padding: 0px 10px;
background: #DAE2F8;
font-family: 'Quicksand';
background: linear-gradient(to right, #D6A4A4, #DAE2F8); 
background: -webkit-linear-gradient(to right, #D6A4A4, #DAE2F8);
`



function Videos() {
    return (
        <Box>
            <VideoPlayer />
        </Box>
    )
}

export default Videos;